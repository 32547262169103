import { DateFormat } from '@/common/interfaces/api/DateFormat';
import { formatEST } from '../../common/util/dates/format/formatEST';
import { getDate } from '../../common/util/dates/getDate';
import styles from './dates.module.scss';

export function formatShortDateTime(dateString: string): string {
    const date = getDate(dateString);
    if (!date) {
        return undefined;
    }

    return formatEST(date, 'M/d h:mm a');
}

export function formatTime(dateString: string): string {
    const date = getDate(dateString);
    if (!date) {
        return undefined;
    }

    return formatEST(date, 'h:mm a');
}

export function formatLongDate(dateString: string): string {
    const date = getDate(dateString);
    if (!date) {
        return undefined;
    }

    return formatEST(date, 'EEEE, MMMM d, yyyy');
}

export function formatLongDateTime(dateString: string): string {
    const date = getDate(dateString);
    if (!date) {
        return undefined;
    }

    const time = formatTime(dateString);
    const dayOfMonth = formatEST(date, 'MMMM d, yyyy');

    return `${dayOfMonth} at ${time}`;
}

export interface DateTimeParams {
    params: {
        date?: string;
        previousDate?: string;
        dateFormat?: DateFormat;
        colSpan?: number;
    };
}

export function ShortDateTime(params: DateTimeParams) {
    let content = <>-</>;

    const dateString = params?.params?.date;
    if (dateString) {
        content = (
            <>
                <span className="date">{formatShortDate(dateString)}</span>{' '}
                <span className="time">{formatTime(dateString)}</span>
            </>
        );
    }

    return <span className="text-nowrap shortDateTime">{content}</span>;
}

export function formatShortDate(dateString: string): string {
    const date = getDate(dateString);
    if (!date) {
        return undefined;
    }

    return formatEST(date, 'M/d');
}

export function DayDivider(params: DateTimeParams): JSX.Element {
    const dateString = params?.params?.date;
    const formatted = formatLongDate(dateString);

    const previousDate = params?.params?.previousDate;
    const previousFormatted = formatLongDate(previousDate);

    if (formatted === previousFormatted) {
        return null;
    }

    return (
        <>
            <tr className={styles.dayDivider}>
                <th colSpan={params?.params?.colSpan} className="day">
                    {formatted}
                </th>
            </tr>
        </>
    );
}

export function ShortDate(params: DateTimeParams) {
    let content = <>-</>;

    const dateString = params?.params?.date;
    if (dateString) {
        const formatted = formatShortDate(dateString);

        const previousDate = params?.params?.previousDate;
        const previousFormatted = formatShortDate(previousDate);

        // Skip showing the date if it was the same as the previous value
        if (formatted === previousFormatted) {
            content = <>&nbsp;</>;
        } else {
            content = (
                <>
                    <span className="date">{formatShortDate(dateString)}</span>
                </>
            );
        }
    }

    return content;
}

export function ShortTime(params: DateTimeParams) {
    let content;

    // Skip showing time for day format
    if (params?.params?.dateFormat === DateFormat.DAY) {
        content = <>End of Day</>;
    }

    const dateString = params?.params?.date;
    if (dateString) {
        content = (
            <>
                <span className="date">{formatTime(dateString)}</span>
            </>
        );
    }

    return content;
}

export function ShortTimeCell(params: DateTimeParams) {
    return (
        <>
            <td className="dateTime time">
                <ShortTime params={params?.params} />
            </td>
        </>
    );
}

export function ShortDateTimeCells(params: DateTimeParams) {
    return (
        <>
            <td className="dateTime date">
                <ShortDate params={params?.params} />
            </td>
            <td className="dateTime time">
                <ShortTime params={params?.params} />
            </td>
        </>
    );
}

export function ShortDateTimeHeaderCells(params: DateTimeParams) {
    return (
        <>
            <th className="dateTime date">
                <ShortDate params={params?.params} />
            </th>
            <th className="dateTime time">
                <ShortTime params={params?.params} />
            </th>
        </>
    );
}
